import './App.css';

function App() {
    return (
        <div className="App">
            <body>
                <div className="logo-container">
                    <p className="logo-text-primary">
                        Zmieniamy się dla Was . . .
                    </p>
                    <p className="logo-text-secondary">coming soon</p>
                    <img
                        src="bespoke_nav_logo2.png"
                        className="BeSpoke-logo"
                        alt="BeSpoke-logo"
                    />
                    <p className="logo-text-third">Skontaktuj się z nami</p>
                    <div className="info-container">
                        <div className="info-details">
                            <a className="info-link" href="tel:789 028 638">
                                tel. 789 028 638
                            </a>
                            <a
                                className="info-link"
                                href="mailto:bespoke.biuro@gmail.com"
                            >
                                bespoke.biuro@gmail.com
                            </a>
                        </div>
                        <div className="info-address-map-container">
                            <div className="info-address-container">
                                <p className="info-adress-text">
                                    ul. Europejska 74
                                </p>
                                <p className="info-adress-text">
                                    {' '}
                                    62-500 Konin
                                </p>
                            </div>

                            <iframe
                                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d9783.765220549989!2d18.2467425!3d52.1899396!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471b3126f119ffd3%3A0xa6f2f6778708aeae!2sBespoke%20Studio!5e0!3m2!1spl!2spl!4v1699554351522!5m2!1spl!2spl"
                                width="600"
                                height="450"
                                className="info-map-iframe-small"
                                allowfullscreen=""
                                loading="lazy"
                                referrerpolicy="no-referrer-when-downgrade"
                                title="google-map-bespoke"
                            ></iframe>
                        </div>
                    </div>
                </div>
            </body>
        </div>
    );
}

export default App;
